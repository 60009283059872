import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('storePpal',{
    state : () =>{
        return {
            dev : 'http://localhost:8081/apigeincca/public/api/',
            urlPpal : 'https://apivalerana.geincca.com/api/',
            deposito : '800',
            ventasOficina : 'VEN000'
        }
    }
})