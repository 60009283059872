<template>
    <div class="row">
          <div v-if="dis_chart" class="col-md-6 mb-3">
            <div class="card h-100">
              <div class="card-header">
                <span class="me-2"><i class="bi bi-bar-chart-fill"></i></span>
                Cuota vs Cobrado
              </div>
              <div class="card-body">
                <div>
                  <apexchart width="500" type="bar" :options="options" :series="series"></apexchart>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dis_table" class="col-md-6 mb-3">
            <div class="card">
              <div class="card-header">
                <span><i class="bi bi-table me-2"></i></span> Cobros Vendedores del dia
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table
                    id="example"
                    class="table table-striped data-table"
                    style="width: 100%"
                  >
                    <tbody>
                        <tr>
                            <td>Ruta</td>
                            <td>Vendedor</td>
                            <td>Cuota dia</td>
                            <td>Monto Cobrado</td>
                        </tr>
                      <tr v-for="ruta in cobros_ve" :key="ruta.Codvend">
                        <td>{{ ruta.CodVend }}</td>
                        <td>{{ ruta.Descrip }}</td>
                        <td style="text-align: right;">{{ $filters.currency(ruta.CuotaCobranza) }}</td>
                        <td style="text-align: right;">{{ $filters.currency(ruta.Monto) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
    </div>
</template>
<script setup>
  import{ onMounted,ref,defineProps } from 'vue'
  import { useGlobalStore } from '@/store/Global'
  import CobrosVendedores from '@/services/CobrosVendedores'

  const store = useGlobalStore()
  let series = ref([])
  let options = ref({})
  let datosy1 = []
  let datosy2 = []
  let datosx = []

  defineProps({
        dis_chart : Boolean,
        dis_table : Boolean
  })

  const datacobven = new CobrosVendedores()
  const cobros_ve = datacobven.getCobrosVendedores()

  onMounted( async ()=>{
      await datacobven.fetchCobrosVendedores(store.urlPpal)
      
      cobros_ve.value.map(elem => datosy1.push(elem.CuotaCobranza))
      cobros_ve.value.map(elem => datosy2.push(elem.Monto))
      cobros_ve.value.map(elem => datosx.push(elem.CodVend))

      var quitare1 = datosy1.pop(); 
      var quitare2 = datosy2.pop(); 
      var quitare3 = datosx.pop();

      series.value = [{
        name: 'Cuota',
        data: datosy1
      },{
        name: 'Cobrado',
        data: datosy2
      }]

      options.value = {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
            bar: {
            borderRadius: 5,
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: datosx,
        },
        yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
              }
            
        },
      }
  })

</script>