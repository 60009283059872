<template>
    <div class="row">
          <div v-if="dis_chart" class="col-md-6 mb-3">
            <div class="card h-100">
              <div class="card-header">
                <span class="me-2"><i class="bi bi-bar-chart-fill"></i></span>
                Cuota vs Vendido en Cajas
              </div>
              <div class="card-body">
                <div>
                  <apexchart width="500" type="bar" :options="options" :series="series"></apexchart>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dis_table" class="col-md-6 mb-3">
            <div class="card">
              <div class="card-header">
                <span><i class="bi bi-table me-2"></i></span> Ventas del mes por Cajas 
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table
                    id="example"
                    class="table table-striped data-table"
                    style="width: 100%"
                  >
                    <tbody>
                        <tr>
                            <td>Ruta</td>
                            <td>Nombre</td>
                            <td>Cuota Mes</td>
                            <td>Venta Mes</td>
                        </tr>
                      <tr v-for="ruta in ventasmesData" :key="ruta.Codvend">
                        <td>{{ ruta.CodVend }}</td>
                        <td>{{ ruta.nombre }}</td>
                        <td style="text-align: right;">{{ $filters.currency(ruta.CuotaMes) }}</td>
                        <td style="text-align: right;">{{ $filters.currency(ruta.Cantidad) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
    </div>
</template>
<script setup>
  import{ onMounted,ref,defineProps } from 'vue'
  import { useGlobalStore } from '@/store/Global'
  import VentasMesVendedorCajasService from '@/services/VentasMesVendedorCajas';

  const store = useGlobalStore()
  let series = ref([])
  let options = ref({})
  let datosy1 = []
  let datosy2 = []
  let datosx = []

  defineProps({
        dis_chart : Boolean,
        dis_table : Boolean
  })

  const datavenmescaja = new VentasMesVendedorCajasService()
  const ventasmesData = datavenmescaja.getVentasMesVendCajas()

  onMounted( async ()=>{
      await datavenmescaja.fetchVentasMesVendCajas(store.urlPpal)
      
      ventasmesData.value.map(elem => datosy1.push(elem.CuotaMes))
      ventasmesData.value.map(elem => datosy2.push(elem.Cantidad))
      ventasmesData.value.map(elem => datosx.push(elem.CodVend))

      series.value = [{
        name: 'Cuota Mes',
        data: datosy1,
        color : '#ff5732'
      },{
        name: 'Vendido',
        data: datosy2,
        color : '#48ff32'
      }]

      options.value = {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
            bar: {
            borderRadius: 5,
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: datosx,
        },
        yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
              }
            
        },
      }
  })

</script>