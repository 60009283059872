import { ref } from 'vue';


class CobrosVendedores {
     
    constructor(){
        this.cobrosvendedores = ref([])
        this.respuesta = ref([])
    }

    getCobrosVendedores(){
        return this.cobrosvendedores
    }

    async fetchCobrosVendedores(urlPpal){
        try{
            const url = urlPpal+"cobrosvendedores"
            const respuesta = await fetch(url)
            const json = await respuesta.json()
            this.cobrosvendedores.value = await json
        }
        catch(error){
            console.log(error)
        }
    }

    async fetchEditCuotaVendedor(urlPpal,Codvend,MontoCuota,MontoVentaMes){
        try{
            const res = await fetch(urlPpal+'editcuotacobrovendedor',{
                method : 'POST',
                headers : {
                    'Accept' : 'application/json',
                    'Content-Type' : 'application/json'
                },
                body : JSON.stringify({
                    codvend : Codvend,
                    montocuota : MontoCuota,
                    montoventames : MontoVentaMes
                })
            })

            let response = await res.json()
            this.respuesta.value = await response

        }
        catch(error){
            console.log(error)
        }
    }
}

export default CobrosVendedores