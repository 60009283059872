<template>
    <main class="mt-5 pt-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <p><b>Estadisticas del mes</b> - dias laborales: {{ $filters.currency(diaslabmes) }} - laborados : {{ $filters.currency(laborados) }} - por laborar: {{ $filters.currency(porlaborar) }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 mb-3">
            <div class="card bg-primary text-white h-100">
              <div class="card-body py-3">{{ datcard1.descripcion }} <br>
                <h6 class="mt-2">Cajas : {{ $filters.currency(datcard11.monto) }}</h6>
              <h6 class="mt-2">Monto : {{ $filters.currency(datcard1.monto) }}</h6></div>
              <div class="card-footer d-flex">
                <router-link :to="{ name : 'ventas' }" style="text-decoration: none;color: white;">Ver Detalle</router-link> 
                <span class="ms-auto">
                  <i class="bi bi-chevron-right"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="card bg-warning text-dark h-100">
              <div class="card-body py-3">{{ datcard2.descripcion }}<br>
              <h6 class="mt-2">Cajas : {{ $filters.currency(datcard2.monto) }}</h6>
              <h6 class="mt-2">Costo : {{ $filters.currency(datcard21.monto) }}</h6>
              <!-- br>costo : {{ $filters.currency(costoinv) }} -->
              </div>
              <div class="card-footer d-flex">
                <router-link :to="{ name : 'inventario' }" style="text-decoration: none;color: black;">Ver Detalle</router-link>
                <span class="ms-auto">
                  <i class="bi bi-chevron-right"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="card bg-success text-white h-100">
              <div class="card-body py-3">{{ datcard3.descripcion }}<br>
                <h6 class="mt-2">Cajas : {{ $filters.currency(datcard31.monto) }}</h6>
              <h6 class="mt-2">Monto : {{ $filters.currency(datcard3.monto) }}</h6></div>
              <div class="card-footer d-flex">
                <router-link :to="{ name : 'compras' }" style="text-decoration: none;color: black;">Ver Detalle</router-link>
                <span class="ms-auto">
                  <i class="bi bi-chevron-right"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="card bg-danger text-white h-100">
              <div class="card-body py-3">{{ datcard4.descripcion }} : {{ $filters.currency(datcard4.monto) }}<br>
                <h6 class="mt-2">DIAS INVENTARIO : {{ $filters.currency(datcard41.monto) }}</h6>
              <h5 class="mt-2"></h5></div>
              <div class="card-footer d-flex">
                Ver Detalles
                <span class="ms-auto">
                  <i class="bi bi-chevron-right"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <PieBloques dis_pie  dis_table />
        </div>
        <div class="mb-3">>
          <ChartVentasMesVendedorCajas dis_chart dis_table/>
        </div>
        <!-- div class="mb-3">
          <ChartVentasRutas dis_chart dis_table />
        </div -->
        <div class="mb-3">
          <ChartCobrosVendedores dis_chart dis_table />
        </div>
      </div>
    </main>
</template>

<script setup>
  import{ onMounted,ref } from 'vue'
  import { useGlobalStore } from '@/store/Global'
  import DatamasterService from '@/services/DatamasterService'

  import ChartVentasMesVendedorCajas from '@/components/ChartVentasMesVendedorCajas.vue';
  //import ChartVentasRutas from '@/components/ChartVentasRutas.vue';
  import PieBloques from '@/components/PieBloques.vue';
  import ChartCobrosVendedores from '@/components/ChartCobrosVendedores.vue'

  const store = useGlobalStore()
  
  let diaslabmes = ref(0)
  let laborados = ref(0)
  let porlaborar = ref(0)
  let cuotafemsa = ref(0)

  let datcard1 = ref({})
  let datcard11 = ref({})
  let datcard2 = ref({})
  let datcard21 = ref({})
  let datcard3 = ref({})
  let datcard31 = ref({})
  let datcard4 = ref({})
  let datcard41 = ref({})
  let costoinv = ref(0)

  const datamaster = new DatamasterService()
  const datos_dm =  datamaster.getDatos()

  onMounted( async ()=>{
      await datamaster.fetchAll(store.urlPpal,store.deposito)
      diaslabmes.value = datos_dm.value[1].monto
      laborados.value = datos_dm.value[2].monto
      porlaborar.value = datos_dm.value[3].monto
      cuotafemsa.value = datos_dm.value[4].monto

      datcard1.value = datos_dm.value[9]
      datcard11.value = datos_dm.value[13]
      datcard2.value = datos_dm.value[8]
      datcard21.value = datos_dm.value[7]
      datcard3.value = datos_dm.value[18]
      datcard31.value = datos_dm.value[19]
      datcard4.value = datos_dm.value[35]
      datcard41.value = datos_dm.value[16]
      costoinv.value = datos_dm.value[7].monto
  }) 
</script>
