import { ref } from 'vue';


class DatamasterService {
     
    constructor(){
        this.registros = ref([])
        this.registrosCob = ref([])
    }

    getDatos(){
        return this.registros
    }

    getdatosCob(){
        return this.registrosCob
    }

    async fetchAll(urlPpal,deposito){
        try{
            const url = urlPpal+"datamaster/"+deposito
            const respuesta = await fetch(url)
            const json = await respuesta.json()
            this.registros.value = await json
        }
        catch(error){
            console.log(error)
        }
    }

    async fetchRegCob(urlPpal,deposito){
        try{
            const url = urlPpal+"datamasterby3/"+deposito
            const respuesta = await fetch(url)
            const json = await respuesta.json()
            this.registrosCob.value = await json
        }
        catch(error){
            console.log(error)
        }
    }
}

export default DatamasterService