<template>
    <div class="row">
        <div v-if="dis_pie" class="col-md-6">
            <div class="card h-100">
              <div class="card-header">
                <span class="me-2"><i class="bi bi-bar-chart-fill"></i></span>
                Cuentas por cobrar
              </div>
              <div class="card-body">
                <div id="chart1">
                  <apexchart type="pie" width="350" :options="pieChartOptions" :series="pieSeries"></apexchart>
                </div>
              </div>
            </div>
        </div>
        <div v-if="dis_table" class="col-md-6">
            <div class="card">
              <div class="card-header">
                <span><i class="bi bi-table me-2"></i></span> Cuentas por Cobrar
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table
                    id="example"
                    class="table table-striped data-table"
                    style="width: 100%"
                  >
                    <tbody>
                      <tr v-for="bloque in datos_bl" :key="bloque.BLOQUE">
                        <td>{{ bloque.BLOQUE }}</td>
                        <td style="text-align: right;">{{ $filters.currency(bloque.TotalBs) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted,ref,defineProps } from 'vue';
    import { useGlobalStore } from '@/store/Global'
    import BloquesService from '@/services/BloquesService'

    defineProps({
        dis_pie : Boolean,
        dis_table : Boolean
    })

    const store = useGlobalStore()
    const resumenBloques = new BloquesService()
    const datos_bl =  resumenBloques.getBloquesResumen()

    let pieSeries = ref([])
    let pieChartOptions = ref({})

    let datos = []
    let labels = []

     onMounted( async ()=>{
        await resumenBloques.fetchBloquesResumen(store.urlPpal,store.ventasOficina)

        datos_bl.value.map(elem => datos.push( parseFloat(elem.TotalBs)))
        datos_bl.value.map(elem => labels.push(elem.BLOQUE))

        var quitare1 = datos.pop(); 
        var quitare2 = labels.pop();

        pieSeries.value = datos
        pieChartOptions.value = {
                chart: {
                width: 380,
                type: 'pie',
                },
                labels: labels,
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 200
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
        } 
     })
</script>