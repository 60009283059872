import { ref } from 'vue';


class BloquesService {
     
    constructor(){
        this.bloquesResumen = ref([])
    }

    getBloquesResumen(){
        return this.bloquesResumen
    }

    async fetchBloquesResumen(urlPpal,ventasOficina){
        try{
            const res = await fetch(urlPpal+'resumenbloques',{
                method : 'POST',
                headers : {
                    'Accept' : 'application/json',
                    'Content-Type' : 'application/json'
                },
                body : JSON.stringify({
                    vendedor : ventasOficina
                })
            })

            let response = await res.json()
            this.bloquesResumen.value = await response

        }
        catch(error){
            console.log(error)
        }
    }
}

export default BloquesService