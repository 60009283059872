import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/editdata',
    name: 'editdata',
    component: () => import(/* webpackChunkName: "editdata" */ '../views/EditDataView.vue')
  },
  {
    path: '/porcobrar',
    name: 'porcobrar',
    component: () => import(/* webpackChunkName: "porcobrar" */ '../views/PorCobrarView.vue')
  },
  {
    path : '/ventas',
    name : 'ventas',
    component : () => import(/* webpackChunkName: "ventas" */ '../views/VentasView.vue')
  },
  {
    path : '/inventario',
    name : 'inventario',
    component : () => import(/* webpackChunkName: "inventario" */ '../views/InventarioView.vue')
  },
  {
    path : '/compras',
    name : 'compras',
    component : () => import(/* webpackChunkName: "compras" */ '../views/ComprasView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
